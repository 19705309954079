<template>
  <div class='card-person bg-secondary h-100 card-desktop'>
    <div class="h-100 py-2">
      <div class="d-flex flex-column features h-100 py-3 px-4">
        <p class="mt-2 mb-0 name text-danger font-weight-bold">{{ firstname }}</p>
        <p class="subtitle font-weight-bold">{{ work }}</p>
        <p class="text">{{ text }}</p>
        <p class="collapse" id="featuresOpen">{{ opencontent }}</p>
        <button @click="toggleFeatures" class="toggle-features p-0 mt-auto ml-0 mr-0 subtitle" type="button" data-toggle="collapse" data-target="#featuresOpen" aria-controls="featuresOpen" aria-expanded="featuresOpen ? 'true' : 'false'">
          {{ featuresOpen ? 'Lire moins' : 'Lire plus' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: String,
    firstname: String,
    work: String,
    text: String,
    opencontent: String
  },
  name: 'card-person',
  data () {
    return {
      featuresOpen: false
    }
  },
  methods: {
    toggleFeatures () {
      this.featuresOpen = !this.featuresOpen
    }
  }
}
</script>
<style scoped lang="scss">
  .card-person{
    position: relative;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    padding: 36px 32px 36px 32px;
    margin-bottom: 10px;
    min-width: 20%;
    min-height: 20%;
    &.card-desktop {
      padding: 0;
      box-shadow: 0px 3px 8px #00000029;
    }
    .toggle-features{
      border: none;
      background-color: transparent;
      font-size: 14px;
      margin-bottom: 0;
    }
  }

</style>
