<template>
  <div>
    <div class="d-block d-lg-none">
      <div class="d-flex header-other">
        <img :src="require('@/assets/img/img1.png')" />
        <div class="bg-danger ml-auto"></div>
      </div>

      <div class="container pt-5">
        <TitleTextButton
          :title="true"
          :text="true"
          :titleContent="title"
          :textContent="text"
        />
      </div>
    </div>

    <div class="d-none d-lg-block">
      <div class="d-flex">
        <div data-width="19" class="column img">
          <img :src="require('../assets/img/img5.png')" alt="" />
        </div>

        <div data-width="23.6" class="column img">
          <img :src="require('../assets/img/img1.png')" alt="" />
        </div>

        <div data-width="29.6" class="column bg-danger"></div>

        <div data-width="25.2" class="column img">
          <img :src="require('../assets/img/img3.png')" alt="" />
        </div>

        <div data-width="auto" class="column"></div>
      </div>
      <div class="d-flex">
        <div data-width="13" class="column bg-danger"></div>

        <div data-width="17.5" :class="'column img ' + (route == '/open-innovation' ? 'order-1' : '')">
          <img :src="require('../assets/img/img4.png')" alt="" />
        </div>

        <div data-width="56.5" :class="'title d-flex ' + (route == '/open-innovation' ? 'order-0' : '')">
          <h2 class="my-auto mx-5" v-html="title"></h2>
        </div>

        <div data-width="13" :class="'column bg-danger ' + (route == '/open-innovation' ? 'order-2' : '')"></div>
      </div>
      <div class="d-flex">
        <div data-width="25.2" class="column img">
          <img :src="require('../assets/img/img6x2.png')" alt="" />
        </div>

        <div data-width="17.5" class="column bg-danger"></div>

        <div data-width="38.2" class="column text d-flex">
          <p class="my-auto mx-2" v-html="text"></p>
        </div>

        <div data-width="19.1" class="column img">
          <img :src="require('../assets/img/img7x2.png')" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleTextButton from './TitleTextButton'

export default {
  data () {
    return {
      route: this.$route.path
    }
  },
  components: {
    TitleTextButton
  },
  props: {
    title: String,
    text: String
  },
  mounted () {
    const cols = document.querySelectorAll('.column')
    cols.forEach(col => {
      const colWidth = col.getAttribute('data-width')
      if (colWidth !== 'auto') {
        col.style.width = colWidth + '%'
      } else {
        col.style.width = colWidth
      }
      if (col.classList.contains('img')) {
        const src = col.children[0].getAttribute('src')
        col.children[0].style.display = 'none'
        col.style.background = "url('" + src + "') center center no-repeat"
        col.style.backgroundSize = 'cover'
      }
    })
  }
}
</script>
<style scoped lang="scss">
.header-other{
  height: 196px;
  img{
    width: 228px;
  }
  .bg-danger{
    width: 137px;
  }
}
.d-flex{
  height: 190px;
  width: 100%;
}
</style>
